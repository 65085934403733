<template>
    <!-- Momento 2 es reclamo -->
    <!-- solo para el módulo Soporte -->
    <chat
    :desfase="0"
    :momento="1"
    finalizado
    :limitar-mensajes="false"
    :tipo="5"
    :user="id_pedido"
    :fecha-finalizado="detalle.fecha_finalizado_chat"
    />
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            detalle: 'pedidos/pedidos_admin/pedido',
        }),

    },

}
</script>

<style lang="css" scoped>
</style>
